<style scoped>
.row {
  justify-content: space-between;
}
</style>

<template>
  <div class="row">
    <div
      v-for="(item, index) in pricongData"
      :key="index"
      class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
      <div class="card border-0 shadow">
        <div class="p-4 border-bottom border-light">
          <h6 class="fw-semibold mb-3 text-uppercase">{{ item.title }}</h6>
          <p class="text-muted mb-0">{{ item.desc }}</p>

          <div class="d-flex my-4">
            <span class="text-muted align-self-end mb-1">A partir de </span>
            <span class="price h3 fw-semibold mb-0">{{ item.value }}€</span>
          </div>

          <a
            href="https://calendly.com/brimel-webdev/30min"
            class="btn btn-soft-primary w-100"
            target="_blank"
            >Prendre contact ></a
          >
        </div>

        <div class="p-4">
          <h6 class="mb-3">Features:</h6>
          <ul class="mb-0 list-unstyled">
            <li
              v-for="(el, index) in item.feature"
              :key="index"
              class="text-muted mt-2">
              <span class="h5 me-1 text-success"
                ><i class="align-middle" :class="el.icon"></i
              ></span>
              {{ el.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--end col-->
  </div>
  <!--end row-->
</template>

<script setup>
import { ref } from "vue";

let pricongData = ref([
  {
    title: "Service d'audit",
    desc: "Optimisez la SEO et l'accessibilité de votre site web",
    value: "99",
    feature: [
      {
        icon: "uil uil-check-circle",
        title: "Optimisation SEO",
      },
      {
        icon: "uil uil-check-circle",
        title: "Optimisation accessibilité",
      },
      {
        icon: "uil uil-check-circle",
        title: "Site web plus rapide",
      },
      {
        icon: "uil uil-check-circle",
        title: "Livraison en moins d'une semaine",
      },
    ],
  },
  {
    title: "Site web sur mesure",
    desc: "Pour les entreprises en besoin de visibilité",
    value: "999",
    feature: [
      {
        icon: "uil uil-check-circle",
        title: "Optimisation SEO",
      },
      {
        icon: "uil uil-check-circle",
        title: "Nom de domaine offert un an",
      },
      {
        icon: "uil uil-check-circle",
        title: "Suivi post-livraison en option",
      },
      {
        icon: "uil uil-check-circle",
        title: "Livraison en une semaine",
      },
    ],
  },
  {
    title: "Développement de SaaS",
    desc: "Pour lancer votre activité en toute sérénité",
    value: "4999",
    feature: [
      {
        icon: "uil uil-check-circle",
        title: "Front-end et back-end",
      },
      {
        icon: "uil uil-check-circle",
        title: "Base de donnée MongoDB",
      },
      {
        icon: "uil uil-check-circle",
        title: "Intégration Stripe",
      },
      {
        icon: "uil uil-check-circle",
        title: "Temps de livraison à définir",
      },
    ],
  },
]);
</script>
